import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"

import { parseShortcodes } from "../lib/shortcodes"

const IndexPage = ({ location }) => {
  const homePageQueryData = useStaticQuery(HOME_PAGE_QUERY)
  const components = parseShortcodes(homePageQueryData.seaPage.content)

  return (
    <Layout location={location}>
      <SEO title={"Home"} location={location} yoast={homePageQueryData.seaPage.seo} />
      <Content className="page-fullwidth-section">
        {components.map((el) => el)}
      </Content>
    </Layout>
  )
}

export default IndexPage

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -2.2rem;
`

// ===============
//      QUERY
// ===============

const HOME_PAGE_QUERY = graphql`
  {
    seaPage(slug: { eq: "home" }) {
      slug
      content
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
